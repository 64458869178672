import React from "react"

import Layout from "../../components/Layout"

const AdminNewsPage = () => (
  <Layout>
    <h1>AdminNews Page</h1>
  </Layout>
)

export default AdminNewsPage
